import React from "react"
import { Button } from "../components/Buttons"
import Layout from "../components/Layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>
      <span role="img" aria-label="Emoji">
        🤦‍♂️
      </span>
      NOT FOUND
    </h1>
    <Button text="Quiero ver los proyectos >>" url="/projects" large="true" />
  </Layout>
)

export default NotFoundPage
